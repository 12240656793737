.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.spinner {
  width: 24px;
  height: 24px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-modal .modal-content {
  background-color: #282c34 !important; /* Dark background for the entire modal */
  border-radius: 15px; /* Rounded corners */
  color: white;
}

.custom-modal .modal-header .close {
  color: white; /* Ensure the close button (X) is also white */
  opacity: 1;
}

.custom-modal .modal-header .close:hover {
  color: #007bff; /* Change close button color on hover */
}

.custom-modal .modal-header .close {
  color: black !important; /* Set the X button to black */
}

.custom-modal .modal-header .close:hover {
  color: #007bff !important; /* Change the X button color on hover */
}

/* For 2x2 grid when stencil image exists */
.grid-item-2x2 {
  flex-basis: 48%; /* Close to half */
  max-width: 48%;
}

/* For 1 row of 3 items when stencil image doesn't exist */
.grid-item-3-in-row {
  flex-basis: 30%;
  max-width: 30%;
}

/* Ensure the layout stays consistent even on small screens */
@media (max-width: 768px) {
  .grid-item-2x2 {
    flex-basis: 48%; /* Force the 2x2 layout on smaller screens */
    max-width: 48%;
  }

  .grid-item-3-in-row {
    flex-basis: 30%; /* Ensure the 1 row of 3 layout on small screens */
    max-width: 30%;
  }
}
